import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route,  Switch } from 'react-router-dom'

import Login from './components/auth/Login'
import Logout from './components/auth/Logout'
import ProtectedRoute from './components/auth/ProtectedRoute'
import Header from './components/layouts/Header'
import Footer from './components/layouts/Footer'
import UpdateConfirmation from './components/forms/UpdateConfirmation'
// import Footer from './components/layouts/Footer'

import FormState from './components/context/form/FormState'
import AuthState from './components/context/auth/AuthState'

import './App.css'
import 'react-datepicker/dist/react-datepicker.css'

import EndForm from './components/forms/EndForm'
import Section2 from './components/forms/Section2'
import Section3 from './components/forms/Section3'
import Section4 from './components/forms/Section4'
import Section5 from './components/forms/Section5'
import Section6 from './components/forms/Section6'
import Section7 from './components/forms/Section7'
import Section8 from './components/forms/Section8'

function App() {
  return (
    <Fragment>
      <AuthState>
        <FormState>
          <Router>
            <Header />
            <div className='container'>
              <Switch>
                <Route exact path='/' component={Login}></Route>
                <ProtectedRoute exact path='/logout' component={Logout}></ProtectedRoute>
                <ProtectedRoute exact path='/form' component={UpdateConfirmation}></ProtectedRoute>
                <ProtectedRoute exact path='/form/end' component={EndForm}></ProtectedRoute>
                <ProtectedRoute exact path='/form/section2' component={Section2}></ProtectedRoute>
                <ProtectedRoute exact path='/form/section3' component={Section3}></ProtectedRoute>
                <ProtectedRoute exact path='/form/section4' component={Section4}></ProtectedRoute>
                <ProtectedRoute exact path='/form/section5' component={Section5}></ProtectedRoute>
                <ProtectedRoute exact path='/form/section6' component={Section6}></ProtectedRoute>
                <ProtectedRoute exact path='/form/section7' component={Section7}></ProtectedRoute>
                <ProtectedRoute exact path='/form/section8' component={Section8}></ProtectedRoute>
              </Switch>
            </div>
            <Footer />
          </Router>
        </FormState>
      </AuthState>
    </Fragment> 
  )
}

export default App

import React, { Fragment, useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import TakeTheShot from '../../images/take-the-shot.png'
import FormContext from '../context/form/formContext'
import AuthContext from '../context/auth/authContext'
import Spinner from '../layouts/Spinner'

const Section3 = () => {
  const history = useHistory()
  const formContext = useContext(FormContext)
  const authContext = useContext(AuthContext)
  const previousAnswer = formContext.previousAnswer
  const currentUserEmail = authContext.currentUser

  const [ thoughts, setThoughts ] = useState(formContext.thoughts)
  const [ error, setError ] = useState(false)
  const [ answer ] = useState(previousAnswer ? previousAnswer : [])
  const [ spinner, setSpinner ] = useState(false)

  const handleChange = (e) => {
    formContext.setThoughts(e.target.value)
    setThoughts(e.target.value)
  }

  const handleBack = (e) => {
    e.preventDefault()
    history.push('/form/section2')
  }

  const handleNext = async (e) => {
    e.preventDefault()
    if (thoughts) {
      setSpinner(true)
      const finalData = await getFinalData()
      formContext.submitUpdate(finalData)
      setSpinner(false)
      history.push('/form/end')
    } else {
      setError(true)
    }
  }

  const getFinalData = () => {
    const final = {
      date: moment(new Date()).format('MM/DD/YYYY HH:mm:ss'),
      currentuser: currentUserEmail,
      vaccinationWillingness: formContext.vaccinationWillingness ? formContext.vaccinationWillingness: previousAnswer ? previousAnswer[1]: '',
      thoughts: formContext.thoughts ? formContext.thoughts : previousAnswer ? previousAnswer[2] : '',
      registrationCompletion: '',
      registrationPlatform: '',
      mySJID: '',
      bookingConfirmation: '',
      firstDoseConfirmation: '',
      firstDoseDate: '',
      firstDoseVaccine: '',
      secondDoseConfirmation: '',
      secondDoseDate: '',
      secondDoseVaccine: '',
      vaccinationStatusUpdate: formContext.vaccinationStatusUpdate
    }

    return final
  }

  useEffect(() => {
    if (formContext.thoughts) {
      setThoughts(formContext.thoughts)
    } else {
      setThoughts(previousAnswer[2])
    }
  }, [])

  if (spinner) {
    return(
      <Fragment>
        <Spinner />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <div className='container'>
          <div className='text-center align-items-center justify-content-center mt-2'>
            <img src={TakeTheShot} alt='Take The Shot'/>
          </div>
          <form>
          <div className='required required-label'>* Required</div>
          <div className='form-margin align-items-center justify-content-center mt-3'>
            <div className='card'>
              <div className='card-header'>
                <h3 className='card-title card-sub-title'>Share your thoughts</h3>
              </div>
              <div className='card-body'>
                  <div className='form-group'>
                    { error ? 
                      <div className="alert alert-danger" role="alert">
                        This is a required question. Please choose one option.
                      </div> : ''
                    }
                    <label className='question'>I will not be taking the vaccine due to<span className='required'>*</span></label>
                    <div><label className='text-small'>Previous Answer: {answer[2] || 'N/A'}</label></div>
                    <div className='radio'>
                      <label>
                        <input
                          checked={thoughts === 'Health & Safety concerns'}
                          className='mr-2'
                          type='radio'
                          value='Health & Safety concerns'
                          onChange={handleChange}
                        />
                        Health & Safety concerns
                      </label>
                    </div>
                    <div className='radio'>
                      <label>
                        <input
                          checked={thoughts === 'Maternity & Pregnancy concerns'}
                          className='mr-2'
                          type='radio'
                          value='Maternity & Pregnancy concerns'
                          onChange={handleChange}
                        />
                        Maternity & Pregnancy concerns
                      </label>               
                    </div>
                    <div className='radio'>
                      <label>
                        <input
                          checked={thoughts === 'Religious concerns'}
                          className='mr-2'
                          type='radio'
                          value='Religious concerns'
                          onChange={handleChange}
                        />
                        Religious concerns
                      </label>               
                    </div>
                    <div className='radio'>
                      <label>
                        <input
                          checked={thoughts === 'Others'}
                          className='mr-2'
                          type='radio'
                          value='Others'
                          onChange={handleChange}
                        />
                        Others
                      </label>               
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <button onClick={handleBack} className='btn btn-dark'>Back</button>
            <span className='mx-2'></span>
            <button onClick={handleNext} className='btn btn-dark'>Next</button>
          </div>
          </form>           
        </div>
      </Fragment>
    )
  }
}

export default Section3
import React, { Fragment, useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import TakeTheShot from '../../images/take-the-shot.png'
import FormContext from '../context/form/formContext'
import AuthContext from '../context/auth/authContext'
import Spinner from '../layouts/Spinner'

const Section5 = () => {
  const history = useHistory()
  const formContext = useContext(FormContext)
  const previousAnswer = formContext.previousAnswer
  const authContext = useContext(AuthContext)

  const [ firstDoseAppointment, setFirstAppointment ] = useState(formContext.firstDoseConfirmation)
  const [ error, setError ] = useState(false)
  const [ answer ] = useState(previousAnswer ? previousAnswer : [])
  const [ spinner, setSpinner ] = useState(false)

  const handleChange = (e) => {
    formContext.setFirstDoseConfirmation(e.target.value)
    setFirstAppointment(e.target.value)
  }

  const handleBack = (e) => {
    e.preventDefault()
    history.push('/form/section4')
  }

  const handleNext = async (e) => {
    e.preventDefault()
    if (firstDoseAppointment) {
      if (firstDoseAppointment == 'No') {
        setSpinner(true)
        const finalData = await getFinalData()
        formContext.submitUpdate(finalData)
        setSpinner(false)
        history.push('/form/end')
      } else {
        history.push('/form/section6')
      }
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if (formContext.firstDoseConfirmation) {
      setFirstAppointment(formContext.firstDoseConfirmation)
    } else {
      setFirstAppointment(previousAnswer[7])
    }
  }, [])

  const getFinalData = () => {
    const final = {
      date: moment(new Date()).format('MM/DD/YYYY HH:mm:ss'),
      currentuser: authContext.currentUser,
      vaccinationWillingness: formContext.vaccinationWillingness ? formContext.vaccinationWillingness: previousAnswer ? previousAnswer[1]: '',
      thoughts: formContext.thoughts ? formContext.thoughts : previousAnswer ? previousAnswer[2] : '',
      registrationCompletion: formContext.registrationCompletion ? formContext.registrationCompletion : previousAnswer ? previousAnswer[3] : '',
      registrationPlatform: formContext.registrationPlatform ? formContext.registrationPlatform : previousAnswer ? previousAnswer[4] : '',
      mySJID: formContext.mySJID ? formContext.mySJID : previousAnswer ? previousAnswer[5] : '',
      bookingConfirmation: formContext.bookingConfirmation ? formContext.bookingConfirmation : previousAnswer ? previousAnswer[6] : '',
      firstDoseConfirmation: formContext.firstDoseConfirmation ? formContext.firstDoseConfirmation : previousAnswer ? previousAnswer[7] : '',
      firstDoseDate: '',
      firstDoseVaccine: '',
      secondDoseConfirmation:'',
      secondDoseDate: '',
      secondDoseVaccine: '',
      vaccinationStatusUpdate: formContext.vaccinationStatusUpdate
    }

    return final
  }

  if (spinner) {
    return(
      <Fragment>
        <Spinner />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <div className='container'>
          <div className='text-center align-items-center justify-content-center mt-2'>
            <img src={TakeTheShot} alt='Take The Shot'/>
          </div>
          <div className='required required-label'>* Required</div>
          <div className='form-margin align-items-center justify-content-center mt-3'>
          <form>
            <div className='card'>
              <div className='card-header'>
                <h3 className='card-sub-title'>Your 1st dose vaccine appointment</h3>
              </div>
              <div className='card-body'>
                <div className='form-group'>
                    { error ? 
                      <div className="alert alert-danger" role="alert">
                        This is a required question. Please choose one option.
                      </div> : ''
                    }
                    <label>Do you have your first vaccine dose appointment?<span className='required'>*</span></label>
                    <div><label className='text-small'>Previous Answer: {answer[7] || 'N/A'}</label></div>
                    <div className='radio'>
                      <label>
                        <input
                          checked={firstDoseAppointment === 'Yes'}
                          className='mr-2'
                          type='radio'
                          value='Yes'
                          onChange={handleChange}
                          required
                        />
                        Yes
                      </label>
                    </div>
                    <div className='radio'>
                      <label>
                        <input
                          checked={firstDoseAppointment === 'No'}
                          className='mr-2'
                          type='radio'
                          value='No'
                          onChange={handleChange}
                          required
                        />
                        No
                      </label>               
                    </div>
                  </div>
              </div>
            </div>
            <div className='d-flex'>
              <button onClick={handleBack} className='btn btn-dark'>Back</button>
              <span className='mx-2'></span>
              <button onClick={handleNext} className='btn btn-dark'>Next</button>
            </div>
          </form>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Section5
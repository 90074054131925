import React, { Fragment, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TakeTheShot from '../../images/take-the-shot.png'
import FormContext from '../context/form/formContext'
import LoadingCard from '../components/LoadingCard'

const UpdateConfirmation = () => {
  const history = useHistory()
  const formContext = useContext(FormContext)
  const [ updateStatus, setUpdateStatus ] = useState(formContext.vaccinationStatusUpdate)
  const [ error, setError ] = useState(false)
  const [ spinner, setSpinner ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)

  const handleChange = (e) => {
    e.preventDefault()
    formContext.setVaccinationStatusUpdate(e.target.value)
    setUpdateStatus(e.target.value)
  }

  const handleUpdateConfirmation = async (e) => {
    e.preventDefault()
    if (updateStatus) {
      if (updateStatus === 'No') {
        history.push('/form/end')
      } else {
        setSpinner(true)
        setIsLoading(true)
        await formContext.getPreviousAnswers()
        setSpinner(false)

        setTimeout(() => {
          setIsLoading(false)
          history.push('/form/section2')
        }, 5000)
      }
    } else {
      setError(true)
    }
  }

  return (
    isLoading
    ?
    <LoadingCard spinner={spinner} />
    :
    <Fragment>
      <div className='container'>
        <div className='text-center align-items-center justify-content-center mt-2'>
          <img src={TakeTheShot} alt='Take The Shot'/>
        </div>
        <div className='form-margin align-items-center justify-content-center mt-3'>
          <div className='card'>
            <div className='card-body text-justify'>
              <h3 className='card-title'>Update your vaccination status</h3>
              <p className='card-text'>
                Dear Allstars,
              </p>
              <p className='card-text'>
                Thank you for updating your vaccination status. Your information has been updated in Workday and it has helped our management team move the vaccination agenda forward for Allstars with the various government agencies.
              </p>
              <p className='card-text'>
                To date, some of us have completed our second dose, whilst some of us have just received the first dose or first appointment.
              </p>
              <p className='card-text'>
                It’s important that you continuously update your vaccination status here after you have completed your vaccination at RedQ or other PPVs.
              </p>
              <p className='card-text'>
              Thank you for your understanding. We all have heard Tony say that the end of pandemic is near and it can only be eliminated by all of us getting vaccinated. 
              </p>
              <p className='card-text'>
                Thank you, and let&apos;s <b>#TakeTheShot!</b>  
              </p>
            </div>
          </div>
        </div>
        <div className='required required-label'>* Required</div>
        <div className='form-margin align-items-center justify-content-center mt-3'>
          <form>
            <div className='card-body card-body-question'>
                <div className='form-group'>
                  { error ? 
                    <div className="alert alert-danger" role="alert">
                      This is a required question. Please choose one option.
                    </div> : ''
                  }
                  <label className='question'>
                    <span>Are there updates on your Covid-19 vaccination status since the last survey? (Eg: received appointment, 1st dose, 2nd dose)</span>
                    <span className='required'>*</span>
                  </label>
                  <div className='radio'>
                      <label>
                        <input
                          checked={updateStatus === 'Yes'}
                          className='mr-2'
                          type='radio'
                          value='Yes'
                          onChange={handleChange}
                        />
                        Yes
                      </label>
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={updateStatus === 'No'}
                        className='mr-2'
                        type='radio'
                        value='No'
                        onChange={handleChange}
                      />
                      No
                    </label>
                  </div>
                </div>
            </div>
            <div className='d-flex'><button onClick={handleUpdateConfirmation} className='btn btn-dark'>Next</button></div>
          </form>
        </div>             
      </div>
    </Fragment>
  )
}

export default UpdateConfirmation
import { SET_LOADING, SET_CURRENT_USER } from '../types'

export default (state, action) => {
  switch(action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        loading:false
      }
    default:
      return state
  }
}
import React, { Fragment } from 'react'

const Footer = () => {
  return (
    <Fragment>
      <footer className='bg-light footer'>
        <div className='row'>
          <div className='col-12 text-center footer'>
            AirAsia SEA Sdn Bhd &copy; {new Date().getFullYear()}. All Rights Reserved.
          </div>
        </div>
      </footer>
    </Fragment>
  )
}

export default Footer
import React, { useReducer } from 'react'
import AuthContext from './authContext'
import AuthReducer from './authReducer'
import { SET_LOADING, SET_CURRENT_USER } from '../types'

const AuthState = (props) => {
  const initialState = {
    currentUser: null,
    loading: true
  }

  const [state, dispatch] = useReducer(AuthReducer, initialState)

  const setCurrentUser = (data) => {
    return dispatch({ type: SET_CURRENT_USER, payload: data})
  }

  const setLoading = (data) => {
    return dispatch({ type: SET_LOADING, payload: data})
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser: state.currentUser,
        loading: state.loading,
        setLoading,
        setCurrentUser
      }}
    >
      {// eslint-disable-next-line react/prop-types
      props.children}
    </AuthContext.Provider>
  )
}

export default AuthState
import axios from 'axios'

const BASE_API_URL = process.env.REACT_APP_BASE_URL

const makeAPIRequest = async (method, endPoint, data) => {

  let result = null
  try {
    const reqHeader = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    switch (method) {
      case 'get':
        result = await axios.get(`${BASE_API_URL}/${endPoint}`, reqHeader)
        break
      case 'delete':
        result = await axios.delete(`${BASE_API_URL}/${endPoint}`, reqHeader)
        break
      case 'post':
        result = await axios.post(`${BASE_API_URL}/${endPoint}`, data, reqHeader)
        break
      case 'put':
        result = await axios.put(`${BASE_API_URL}/${endPoint}`, data, reqHeader)
        break
      default:
        result = { message: 'Unknow method.' }
    }

    return result.data
  } catch (error) {
    console.log('error', error)
    const message = error.response.data.message
    return { error: true, message }
  }
}

export default makeAPIRequest


import React, { useEffect, useState } from 'react';
import LoadingGIF from '../../../assets/Ripple-1s-200px.gif'

const LoadingCard = ({ spinner, callback }) => {

  const [countdown, setCountdown] = useState(5)

  const updateCountDown = () => {
    setCountdown(countdown-1)
  }

  useEffect(() => {
    if(spinner === false) {
      const countdownListener = setInterval(() => updateCountDown(), 1000)
      return () => clearInterval(countdownListener);
    }
  }, [spinner, countdown])


  return (
    <div className="container">
      
      <div className="card"></div>
      <div className="card-body" style={{ height: '80vh', display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column' }}>

        <div className='text-center align-items-center justify-content-center mt-2'>
          {
            spinner === false
            ?
              <>
                <p style={{ fontSize: 20, fontWeight: 500, fontFamily: 'sans-serif' }}>Done load the data, let&apos;s update your vaccine info</p>
                <p style={{ fontSize: 14, fontWeight: 500, fontFamily: 'sofia' }}>Redirecting in {countdown}</p>
              </>
            :
              <>
                <p style={{ fontSize: 14, fontWeight: 500 }}>
                  Hang in there, we are getting your data.<br/>
                  <span style={{ fontSize: 16, fontFamily: 'monospace' }}>Meanwhile, please view Tony&apos;s video on Take The Shot</span>
                </p>
                <img src={LoadingGIF} alt="Loading..." style={{ height: 50 }} />
              </>
          }
          <div style={{ width: 150, backgroundColor: 'black', height: 1, margin: 'auto', marginTop: spinner === false ? 10 : -25 }} />
        </div>

        
        <div style={{ borderWidth: 1, borderColor: 'black', borderStyle: 'solid', height: '50vh' }}>
          <video width="100%" height="100%" controls autoPlay={true}>
            <source src="Tony-Take-The-Shot.webm" type="video/webm"/>
            <source src="Tony-Take-The-Shot.mp4" type="video/mp4"/>
          </video>
        </div>
      </div>
    </div>
  );
}

export default LoadingCard;
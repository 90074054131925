import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { GoogleLogout } from 'react-google-login'
import FormContext from '../context/form/formContext'

const clientId = process.env.REACT_APP_CLIENT_ID

const Logout = () => {
  const history = useHistory()
  const formContext = useContext(FormContext)

  const onSuccess = () => {
    formContext.clearState()
    history.push('/')
  }

  return(
    <div className='mt-3 text-center'>
      <GoogleLogout
        clientId={clientId}
        buttonText='Logout'
        onLogoutSuccess={onSuccess}
        isSignedIn={false}
        style={{ marginTop: '100px', marginBottom:'10px'}}
      ></GoogleLogout>
    </div>
  )
}

export default Logout
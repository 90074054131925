import React, { useContext } from "react";
import AuthContext from '../context/auth/authContext';
import { Route, Redirect } from "react-router-dom";
// import Spinner from "../layouts/Spinner";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { currentUser } = authContext;
  console.log('currentUser',currentUser)

  if (!currentUser) {
    return(<Redirect to='/' />)
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          return currentUser ? (
            <Component {...props} />
          ) : (
            <Redirect to='/' />
          );
        }}
      ></Route>
    );
  }
};

export default ProtectedRoute;

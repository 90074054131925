import React, { Fragment } from 'react'
import TakeTheShot from '../../images/take-the-shot.png'
import Logout from '../auth/Logout'

const EndForm = () => {
  return (
    <Fragment>
      <div className='container'>
        <div className='align-items-center justify-content-center mt-2'>
          <img src={TakeTheShot} alt='Take The Shot'/>
        </div>
        <div className='align-items-center justify-content-center mt-3 form-margin'>
          <div className='card'>
            <div className='card-body text-justify'>
              <h3 className='card-title'><strong>Thank you for your support</strong></h3>
              <p className='card-text' style={{'paddingTop': '30px', 'paddingBottom': '20px'}}>
                Please share the word! If there are any changes to your vaccination status in future, please update this form again!
              </p>
              <p className='card-text'>
                <b>#TAKETHESHOT</b>
              </p>
              <Logout />
            </div>
          </div>
        </div>
      </div>
     
    </Fragment>
  )
}

export default EndForm
import React, { useEffect, useState } from 'react';
import { vaccineRegisteredPlatfrom } from '../../forms/section4questions/vaccineRegisteredPlatfrom';

const OptionInput = ({callback, selectedOption}) => {

  const otherIsSelected = () => {
    
    setIsSelected(!isSelected)
    
    const defaultText = {
      target: {
        value: ''
      }
    }

    // Note: Set text to other
    callback(defaultText)
  }

  const [isSelected, setIsSelected] = useState(false)
  
  useEffect(() => {
    
    // Note: Compare with the existing option, if undefine then it is consider as other
    const isOtherOption = vaccineRegisteredPlatfrom.find((each) => each.name === selectedOption);

    if(!isOtherOption && selectedOption !== undefined) {
      setIsSelected(true)
    }
    else {
      setIsSelected(false)
    }

  }, [selectedOption])


  return (
    <>
      <label>
        <input
          checked={isSelected}
          className='mr-2'
          type='radio'
          value='Other'
          onChange={otherIsSelected}
          required
        />
        Other
      </label>

      { isSelected &&
        <input
          className='form-control'
          type='text'
          placeholder='Your answer'
          value={selectedOption}
          onChange={callback}
          required
        />
      }
    </>
  )
}

export default OptionInput;
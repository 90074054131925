import React, { useReducer, useContext } from 'react'
// import  { GoogleSpreadsheet } from 'google-spreadsheet'

import { 
  SET_LOADING,
  RESET_FORM,
  SET_VACCINATION_STATUS_UPDATE,
  SET_VACCINATION_WILLINGNESS,
  SET_THOUGHTS,
  SET_REGISTRATION_COMPLETION,
  SET_REGISTRATION_PLATFORM,
  SET_MYSJID,
  SET_SLOT_BOOKING_CONFIRMATION,
  SET_FIRST_DOSE_CONFIRMATION,
  SET_FIRST_DOSE_DATE,
  SET_FIRST_DOSE_VACCINE,
  SET_SECOND_DOSE_CONFIRMATION,
  SET_SECOND_DOSE_DATE,
  SET_SECOND_DOSE_VACCINE,
  SET_PREVIOUS_ANSWERS
} from '../types'

import FormContext from './formContext'
import FormReducer from './formReducer'
import AuthContext from '../auth/authContext'
import makeAPIRequest from '../../../helpers/makeAPIRequest'

// const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID
// const SHEET_ID = process.env.REACT_APP_SHEET_ID
// const CLIENT_EMAIL = process.env.REACT_APP_CLIENT_EMAIL
// const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY
// const answerSpreadsheet = new GoogleSpreadsheet(SPREADSHEET_ID)

const FormState = (props) => {
  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext.currentUser

  const initialState = {
    loading: false,
    vaccinationStatusUpdate: '',
    vaccinationWillingness: '',
    thoughts: '',
    registrationCompletion: '',
    registrationPlatform: '',
    mySJID: '',
    bookingConfirmation: '',
    firstDoseConfirmation: '',
    firstDoseDate: '',
    firstDoseVaccine: '',
    secondDoseConfirmation: '',
    secondDoseDate: '',
    secondDoseVaccine: '',
    previousAnswer: []
  }

  const [form, dispatch] = useReducer(FormReducer, initialState)

  const clearState = () => {
    return dispatch({ type: RESET_FORM, payload: initialState })
  }

  const setVaccinationStatusUpdate = (data) => {
    return dispatch({ type: SET_VACCINATION_STATUS_UPDATE, payload: data })
  }

  const setLoading = (status) => {
    return dispatch({ type: SET_LOADING, payload: status})
  }

  const setVaccinationWillingness = (data) => {
    return dispatch({ type: SET_VACCINATION_WILLINGNESS, payload: data})
  }

  const setThoughts = (data) => {
    return dispatch({ type: SET_THOUGHTS, payload: data})
  }

  const setRegistrationCompletion = (data) => {
    return dispatch({ type: SET_REGISTRATION_COMPLETION, payload:data})
  }

  const setRegistrationPlatform = (data) => {
    return dispatch({ type: SET_REGISTRATION_PLATFORM, payload: data})
  }

  const setMySJID = (data) => {
    return dispatch({ type: SET_MYSJID, payload: data})
  }

  const setBookingConfirmation = (data) => {
    return dispatch({ type: SET_SLOT_BOOKING_CONFIRMATION, payload: data})
  }

  const setFirstDoseConfirmation = (data) => {
    return dispatch({ type: SET_FIRST_DOSE_CONFIRMATION, payload: data})
  }

  const setFirstDoseDate = (data) => {
    return dispatch({ type: SET_FIRST_DOSE_DATE, payload: data})
  }

  const setFirstDoseVaccine = (data) => {
    return dispatch({ type: SET_FIRST_DOSE_VACCINE, payload: data})
  }

  const setSecondDoseConfirmation = (data) => {
    return dispatch({ type: SET_SECOND_DOSE_CONFIRMATION, payload: data})
  }

  const setSecondDoseDate = (data) => {
    return dispatch({ type: SET_SECOND_DOSE_DATE, payload: data})
  }

  const setSecondDoseVaccine = (data) => {
    return dispatch({ type: SET_SECOND_DOSE_VACCINE, payload: data})
  }

  const getAnswersFromWD = async() => {
    try {
      const res = await makeAPIRequest('get', `data/workday/${currentUserEmail}`)
      
      let workdayData
      if ( res && res.length > 0) {
        workdayData = Object.values(res[0])
      }
      workdayData.splice(5, 1)
      return workdayData
    } catch (error) {
      console.info('error', error)
    }
  }

  const getPreviousAnswers = async () => {  
    try{
      const workdayPrevAns = await getAnswersFromWD()

      if (workdayPrevAns) {
        dispatch({ type: SET_PREVIOUS_ANSWERS, payload: workdayPrevAns})
      } else {

        const spreadsheetPrevAns = await getDataFromSheet()
  
        if(spreadsheetPrevAns) {
          dispatch({ type: SET_PREVIOUS_ANSWERS, payload: spreadsheetPrevAns})
        }
        else {
          // Note: If user does not exist in Spreadsheet and Workday profile
          // 1. set an empty default value, else it becomes undefine. Since this question is not been asked in this form
          dispatch({ type: SET_THOUGHTS, payload: " "})
          dispatch({ type: SET_PREVIOUS_ANSWERS, payload: []})
        }
      }
    } catch(err) {
      console.log('get data from Workprofile Error: ', err)
    }    
  }

  const getDataFromSheet = async() => {
    try {
      const res = await makeAPIRequest('get', `data/sheet/${currentUserEmail}`)
      
      if(res.qna) {
        const ssData = res.qna
        ssData.shift()
        ssData.splice(2, 1)

        return ssData
      }

      return undefined;
    
    } catch (error) { 
      console.log('error', error)
    }
  }

  const submitUpdate = async (data) => {
    try {
      const res = await makeAPIRequest('put', 'data/update/sheet', data)
      console.log('res', res)
      return 'OK'
    } catch(error) {
      console.log('error', error)
    }
  }

  // const getDataFromSpreadsheet = async() => {
  //   try {
  //     await answerSpreadsheet.useServiceAccountAuth({
  //       client_email: CLIENT_EMAIL,
  //       private_key: PRIVATE_KEY.replace(/\\n/g, '\n')
  //     })
  //     await answerSpreadsheet.loadInfo()
  //     const sheet = answerSpreadsheet.sheetsById[SHEET_ID]
  //     const rows = await sheet.getRows()
  //     console.log('rows', rows)
      
  //     let answers = []

  //     await rows.map((data) => {
  //       if(data._rawData[1] === currentUserEmail) {
  //         answers = data._rawData
  //       }
  //     })
  //     answers.shift()
  //     return answers
  //   } catch(error) {
  //     console.log('error', error)
  //   }
  // }

  return (
    <FormContext.Provider
      value={{
        ...form,
        setLoading,
        setVaccinationStatusUpdate,
        setVaccinationWillingness,
        setThoughts,
        setRegistrationCompletion,
        setRegistrationPlatform,
        setMySJID,
        setBookingConfirmation,
        setFirstDoseConfirmation,
        setFirstDoseDate,
        setFirstDoseVaccine,
        setSecondDoseConfirmation,
        setSecondDoseDate,
        setSecondDoseVaccine,
        getPreviousAnswers,
        submitUpdate,
        clearState
      }}
    >
      {
      // eslint-disable-next-line react/prop-types
      props.children}
    </FormContext.Provider>
  )
}

export default FormState
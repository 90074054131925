import React, { Fragment } from 'react'

const Header = () => {
  return (
    <Fragment>
      <div className= 'navbar navbar-expand-lg navbar-light bg-light pb-5'>
        <div className='container'>
          {/* <img src={''} style={{ maxHeight: '50px'}} alt='Logo' /> */}
        </div>
      </div>
    </Fragment>
  )
}

export default Header
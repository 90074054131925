// Form State

export const SET_LOADING = 'SET_LOADING'
export const RESET_FORM = 'RESET_FORM'
export const SET_VACCINATION_STATUS_UPDATE = 'SET_VACCINATION_STATUS_UPDATE'
export const SET_VACCINATION_WILLINGNESS = 'SET_VACCINATION_WILLINGNESS'
export const SET_THOUGHTS = 'SET_THOUGHTS'
export const SET_REGISTRATION_COMPLETION = 'SET_REGISTRATION_COMPLETION'
export const SET_REGISTRATION_PLATFORM = 'SET_REGISTRATION_PLATFORM'
export const SET_MYSJID = 'SET_MYSJID'
export const SET_SLOT_BOOKING_CONFIRMATION = 'SET_SLOT_BOOKING_CONFIRMATION'
export const SET_FIRST_DOSE_CONFIRMATION = 'SET_FIRST_DOSE_CONFIRMATION'
export const SET_FIRST_DOSE_DATE = 'SET_FIRST_DOSE_DATE'
export const SET_FIRST_DOSE_VACCINE = 'SET_FIRST_DOSE_VACCINE'
export const SET_SECOND_DOSE_CONFIRMATION = 'SET_SECOND_DOSE_CONFIRMATION'
export const SET_SECOND_DOSE_DATE = 'SET_SECOND_DOSE_DATE'
export const SET_SECOND_DOSE_VACCINE = 'SET_SECOND_DOSE_VACCINE'
export const SET_PREVIOUS_ANSWERS = 'SET_PREVIOUS_ANSWERS'

// Auth State

export const SET_CURRENT_USER = 'SET_CURRENT_USER'

import React, { Fragment, useContext, useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import TakeTheShot from '../../images/take-the-shot.png'
import FormContext from '../context/form/formContext'
import AuthContext from '../context/auth/authContext'
import Spinner from '../layouts/Spinner'

import { vaccineRegisteredPlatfrom } from './section4questions/vaccineRegisteredPlatfrom'
import OtherOption from '../components/Inputs/OptionsInput'

const Section4 = () => {
  const history = useHistory()
  const formContext = useContext(FormContext)
  const authContext = useContext(AuthContext)
  const previousAnswer = formContext.previousAnswer
  const mySJIDRef = useRef()

  const [ complete, setComplete ] = useState(formContext.registrationCompletion)
  const [ booking, setBooking ] = useState(formContext.bookingConfirmation)
  const [ platform, setPlatform ] = useState(formContext.registrationPlatform)
  const [ mySJID, setMySJID ] = useState(formContext.mySJID)
  const [ error, setError ] = useState(false)
  const [ answer ] = useState(previousAnswer ? previousAnswer : [])
  const [ spinner, setSpinner ] = useState(false)

  useEffect(() => {
    if (formContext.registrationCompletion) {
      setComplete(formContext.registrationCompletion)
    } else {
      setComplete(previousAnswer[3])
    }

    if (formContext.bookingConfirmation) {
      setBooking(formContext.bookingConfirmation)
    } else {
      setBooking(previousAnswer[6])
    }

    if (formContext.registrationPlatform) {
      setPlatform(formContext.registrationPlatform)
    } else {
      setPlatform(previousAnswer[4])
    }

    if (formContext.mySJID) {
      setMySJID(formContext.mySJID)
    } else {
      setMySJID(previousAnswer[5])
    }
  }, [])

  const handleRegistrationCompleteChange = (e) => {
    formContext.setRegistrationCompletion(e.target.value)
    setComplete(e.target.value)
  }

  const handleBookingChange = (e) => {
    formContext.setBookingConfirmation(e.target.value)
    setBooking(e.target.value)
  }

  const handlePlatformChange = (e) => {
    formContext.setRegistrationPlatform(e.target.value)
    setPlatform(e.target.value)
  }

  const handleBack = (e) => {
    e.preventDefault()
    history.push('/form/section2')
  }

  const handleNext = async (e) => {
    e.preventDefault()
    
    if (complete && booking && platform && mySJIDRef.current.value) {
      
      formContext.setMySJID(mySJIDRef.current.value)
      
      if (complete === 'No') {
        setSpinner(true)
        const finalData = await getFinalData()
        formContext.submitUpdate(finalData)
        setSpinner(false)
        history.push('/form/end')
      } else {
        history.push('/form/section5')
      }
    } else {
      setError(true)
    }
  }

  const getFinalData = () => {
    const final = {
      date: moment(new Date()).format('MM/DD/YYYY HH:mm:ss'),
      currentuser: authContext.currentUser,
      vaccinationWillingness: formContext.vaccinationWillingness ? formContext.vaccinationWillingness: previousAnswer ? previousAnswer[1]: '',
      thoughts: formContext.thoughts ? formContext.thoughts : previousAnswer ? previousAnswer[2] : '',
      registrationCompletion: formContext.registrationCompletion ? formContext.registrationCompletion : previousAnswer ? previousAnswer[3] : '',
      registrationPlatform: formContext.registrationPlatform ? formContext.registrationPlatform : previousAnswer ? previousAnswer[4] : '',
      mySJID: formContext.mySJID ? formContext.mySJID : previousAnswer ? previousAnswer[5] : '',
      bookingConfirmation: formContext.bookingConfirmation ? formContext.bookingConfirmation : previousAnswer ? previousAnswer[6] : '',
      firstDoseConfirmation: '',
      firstDoseDate: '',
      firstDoseVaccine: '',
      secondDoseConfirmation:'',
      secondDoseDate: '',
      secondDoseVaccine: '',
      vaccinationStatusUpdate: formContext.vaccinationStatusUpdate
    }

    return final
  }

  if (spinner) {
    return(
      <Fragment>
        <Spinner />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <div className='container'>
          <div className='text-center align-items-center justify-content-center mt-2'>
            <img src={TakeTheShot} alt='Take The Shot'/>
          </div>
          <div className='required required-label'>* Required</div>
          <div className='form-margin align-items-center justify-content-center mt-3'>
            { error ? 
              <div className="alert alert-danger" role="alert">
                All the questions are required question. Please answer all.
              </div> : ''
            }
            <div className='card'>
              <div className='card-header'>
                <h3 className='card-title card-sub-title'>Your registration information</h3>
              </div>
              <div className='card-body'>
                <div className='form-group'>
                  <label className='question'>Have you completed your registration for vaccine?<span className='required'>*</span></label>
                  <div><label className='text-small'>Previous Answer: {answer[3] || 'N/A'}</label></div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={complete === 'Yes'}
                        className='mr-2'
                        type='radio'
                        value='Yes'
                        onChange={handleRegistrationCompleteChange}
                      />
                      Yes
                    </label>
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={complete === 'No'}
                        className='mr-2'
                        type='radio'
                        value='No'
                        onChange={handleRegistrationCompleteChange}
                        required
                      />
                      No
                    </label>               
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-2'>
            <div className='card-body-question'>
              <div className='form-group'>
                <label className='question'>Where have you registered for vaccination?<span className='required'>*</span></label>
                <div><label className='text-small'>Previous Answer: {answer[4] || 'N/A'}</label></div>
                {
                  vaccineRegisteredPlatfrom.map((each, key) => (
                    <div key={key} className='radio'>
                      <label>
                        <input
                          checked={platform === each.value}
                          className='mr-2'
                          type='radio'
                          value={each.value}
                          onChange={handlePlatformChange}
                          required
                        />
                        {each.name}
                      </label>
                    </div>
                  ))
                }

                <div className='radio'>
                  <OtherOption selectedOption={platform} callback={handlePlatformChange} />
                </div>
              </div>
            </div>
            <div className='mt-2'>
              <div className='card-body-question'>
                <label className='question'>Please enter your MySJ ID / Lot # / Registration ID<span className='required'>*</span></label>
                <div><label className='text-small'>Previous Answer: {answer[5] || 'N/A'}</label></div>
                <div className='form-group'>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Your answer'
                    ref={mySJIDRef}
                    defaultValue={mySJID}
                    required
                  />
                </div>
              </div>
            </div>
            <div className='mt-2'>
              <div className='card-body-question'>
                <div className='form-group'>
                  <label>Have you booked a slot for vaccination?<span className='required'>*</span></label>
                  <div><label className='text-small'>Previous Answer: {answer[6] || 'N/A'}</label></div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={booking === 'Yes'}
                        className='mr-2'
                        type='radio'
                        value='Yes'
                        onChange={handleBookingChange}
                        required
                      />
                      Yes
                    </label>
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={booking === 'No'}
                        className='mr-2'
                        type='radio'
                        value='No'
                        onChange={handleBookingChange}
                        required
                      />
                      No
                    </label>               
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex mb-5'>
              <button type='submit' onClick={handleBack} className='btn btn-dark' >Back</button>
              <span className='mx-2'></span>
              <button type='submit' onClick={handleNext} className='btn btn-dark'>Next</button>
            </div>
          </div>             
        </div>
      </Fragment>
    )
  }
}

export default Section4
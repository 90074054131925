import React, { Fragment, useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DatePicker from 'react-datepicker'

import FormContext from '../context/form/formContext'
import TakeTheShot from '../../images/take-the-shot.png'

const Section6 = () => {
  const history = useHistory()
  const formContext = useContext(FormContext)
  const previousAnswer = formContext.previousAnswer

  const [ vaccine, setVaccine ] = useState(formContext.firstDoseVaccine)
  const [ firstDate, setFirstDate ] = useState(formContext.firstDoseDate ? formContext.firstDoseDate : '')
  const [ error, setError ] = useState(false)
  const [ answer ] = useState(previousAnswer ? previousAnswer : [])
  
  const handleVaccineChange = (e) => {
    e.preventDefault()
    formContext.setFirstDoseVaccine(e.target.value)
    setVaccine(e.target.value)
  }

  const handleBack = (e) => {
    e.preventDefault()
    if (firstDate) {
      formContext.setFirstDoseDate(firstDate)
    }
    history.push('/form/section5')
  }

  const handleNext = (e) => {
    e.preventDefault()
    if (firstDate) {
      formContext.setFirstDoseDate(firstDate)
    }

    if (vaccine) {
      history.push('/form/section7')
    } else {
      setError(true)
    }
  }
  useEffect(() => {
    if (formContext.firstDoseVaccine) {
      setVaccine(formContext.firstDoseVaccine)
    } else {
      setVaccine(previousAnswer[9])
    }

    if (firstDate) {
      setFirstDate(firstDate)
    } else {
      const previousDate = previousAnswer ? previousAnswer[8] : ''
      setFirstDate(previousDate ? new Date(previousDate) : '')
    }
  }, [])

  return(
    <Fragment>
      <div className='container'>
        <div className='text-center align-items-center justify-content-center mt-2'>
          <img src={TakeTheShot} alt='Take The Shot'/>
        </div>
        <div className='required required-label'>* Required</div>
        <div className='form-margin align-items-center justify-content-center mt-3'>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title card-sub-title'>Your 1st dose vaccine appoinment</h3>
            </div>
            <div className='card-body'>
              <label className='question'>Date of my first vaccination dose<span className='required'>*</span></label>
              <div><label className='text-small'>Previous Answer: {answer[8]  || 'N/A' }</label></div>
              <div>Date: &nbsp;&nbsp; 
              <DatePicker 
                selected={firstDate}
                onChange={date => setFirstDate(date)} 
                dateFormat='dd/MM/yyyy'
              />
              </div>
            </div>
          </div>
          <div className='mt-2'>
            <div className='card-body-question'>
              <div className='form-group'>
                { error ? 
                  <div className="alert alert-danger" role="alert">
                    This is a required question. Please choose one option.
                  </div> : ''
                }
                <label className='question'>Select the vaccine that you received:<span className='required'>*</span></label>
                <div><label className='text-small'>Previous Answer: {answer[9]  || 'N/A' }</label></div>
                <div className='radio'>
                  <label>
                    <input
                      checked={vaccine === 'Pfizer'}
                      className='mr-2'
                      type='radio'
                      value='Pfizer'
                      onChange={handleVaccineChange}
                      required
                    />
                    Pfizer
                  </label>
                </div>
                <div className='radio'>
                  <label>
                    <input
                      checked={vaccine === 'Astra Zeneca'}
                      className='mr-2'
                      type='radio'
                      value='Astra Zeneca'
                      onChange={handleVaccineChange}
                      required
                    />
                    Astra Zeneca
                  </label>               
                </div>
                <div className='radio'>
                  <label>
                    <input
                      checked={vaccine === 'Sinovac'}
                      className='mr-2'
                      type='radio'
                      value='Sinovac'
                      onChange={handleVaccineChange}
                      required
                    />
                    Sinovac
                  </label>               
                </div>
                <div className='radio'>
                  <label>
                    <input
                      checked={vaccine === 'Sputnik V'}
                      className='mr-2'
                      type='radio'
                      value='Sputnik V'
                      onChange={handleVaccineChange}
                      required
                    />
                    Sputnik V
                  </label>               
                </div>
                <div className='radio'>
                  <label>
                    <input
                      checked={vaccine === 'CanSino'}
                      className='mr-2'
                      type='radio'
                      value='CanSino'
                      onChange={handleVaccineChange}
                      required
                    />
                    CanSino
                  </label>               
                </div>
                <div className='radio'>
                  <label>
                    <input
                      checked={vaccine === 'Sinopharm'}
                      className='mr-2'
                      type='radio'
                      value='Sinopharm'
                      onChange={handleVaccineChange}
                      required
                    />
                    Sinopharm
                  </label>               
                </div>
                <div className='radio'>
                  <label>
                    <input
                      checked={vaccine === 'Other'}
                      className='mr-2'
                      type='radio'
                      value='Other'
                      onChange={handleVaccineChange}
                      required
                    />
                    Other
                  </label>               
                </div>
                <div className='radio'>
                  <label>
                    <input
                      checked={vaccine === 'Unknown'}
                      className='mr-2'
                      type='radio'
                      value='Unknown'
                      onChange={handleVaccineChange}
                      required
                    />
                    Unknown
                  </label>               
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex mt-3 mb-5'>
            <button type='submit' onClick={handleBack} className='btn btn-dark'>Back</button>
            <span className='mx-2'></span>
            <button type='submit' onClick={handleNext} className='btn btn-dark'>Next</button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Section6
import React, { Fragment, useContext } from 'react'
import { GoogleLogin } from 'react-google-login'
import { useHistory } from 'react-router-dom'

import TakeTheShot from '../../images/take-the-shot.png'
import AuthContext from '../context/auth/authContext'

const clientId = process.env.REACT_APP_CLIENT_ID

const Login = () => {
  const history = useHistory()
  const authContext = useContext(AuthContext)

  const onSuccess = (res) => {
    console.log('Login success : ', res.profileObj)
    const profile = res.profileObj
    authContext.setCurrentUser(profile.email)
    history.push('/form')
  }

  const onFailure = (res) => {
    console.log('Login failed', res)
  }

  return (
    <Fragment>
      <div className='container'>
        <div className='text-center align-items-center justify-content-center mt-2'>
            <img src={TakeTheShot} alt='Take The Shot'/>
        </div>
        <div className='align-items-center justify-content-center mt-3 form-margin'>
          <div className='card'>
            <div className='card-body text-justify'>
              <h3 className='card-title text-center'>Update your vaccination status</h3>
              <div className='text-center mt-3' style={{padding: '30px'}}>
                <GoogleLogin
                  clientId={clientId}
                  buttonText="Login with Google"
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={'single_host_origin'}
                  style={{ marginTop: '100px', marginBottom:'10px'}}
                  isSignedIn={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Login

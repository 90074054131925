import { 
  SET_LOADING,
  RESET_FORM,
  SET_VACCINATION_STATUS_UPDATE,
  SET_VACCINATION_WILLINGNESS,
  SET_THOUGHTS,
  SET_REGISTRATION_COMPLETION,
  SET_REGISTRATION_PLATFORM,
  SET_MYSJID,
  SET_SLOT_BOOKING_CONFIRMATION,
  SET_FIRST_DOSE_CONFIRMATION,
  SET_FIRST_DOSE_DATE,
  SET_FIRST_DOSE_VACCINE,
  SET_SECOND_DOSE_CONFIRMATION,
  SET_SECOND_DOSE_DATE,
  SET_SECOND_DOSE_VACCINE, 
  SET_PREVIOUS_ANSWERS} from '../types'

const formReducer = (state, action) => {
  switch (action.type){
    case RESET_FORM:
      return action.payload
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SET_VACCINATION_STATUS_UPDATE:
      return {
        ...state,
        vaccinationStatusUpdate: action.payload
      }
    case SET_VACCINATION_WILLINGNESS:
      return {
        ...state,
        vaccinationWillingness: action.payload
      }
    case SET_THOUGHTS:
      return {
        ...state,
        thoughts: action.payload
      }
    case SET_REGISTRATION_COMPLETION:
      return {
        ...state,
        registrationCompletion: action.payload
      }
    case SET_REGISTRATION_PLATFORM:
      return {
        ...state,
        registrationPlatform: action.payload
      }
    case SET_MYSJID:
      return {
        ...state,
        mySJID: action.payload
      }
    case SET_SLOT_BOOKING_CONFIRMATION:
      return {
        ...state,
        bookingConfirmation: action.payload
      }
    case SET_FIRST_DOSE_CONFIRMATION:
      return {
        ...state,
        firstDoseConfirmation: action.payload
      }
    case SET_FIRST_DOSE_DATE:
      return {
        ...state,
        firstDoseDate: action.payload
      }
    case SET_FIRST_DOSE_VACCINE:
      return {
        ...state,
        firstDoseVaccine: action.payload
      }
    case SET_SECOND_DOSE_CONFIRMATION:
      return {
        ...state,
        secondDoseConfirmation: action.payload
      }
    case SET_SECOND_DOSE_DATE:
      return {
        ...state,
        secondDoseDate: action.payload
      }
    case SET_SECOND_DOSE_VACCINE:
      return {
        ...state,
        secondDoseVaccine: action.payload
      }
    case SET_PREVIOUS_ANSWERS:
      return {
        ...state,
        previousAnswer: action.payload
      }
    default:
      return state
  }
}

export default formReducer
export const vaccineRegisteredPlatfrom = [
  {
    name: 'MySejahtera',
    value: 'MySejahtera',
  },
  {
    name: 'AstraZeneca Volunteer Programme',
    value: 'AstraZeneca Volunteer Programme',
  },
  {
    name: 'RedQ PPV',
    value: 'RedQ PPV',
  },
]
import React, { Fragment, useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import FormContext from '../context/form/formContext'
import AuthContext from '../context/auth/authContext'
import TakeTheShot from '../../images/take-the-shot.png'
import Spinner from '../layouts/Spinner'

const Section8 = () => {
  const history = useHistory()
  const formContext = useContext(FormContext)
  const authContext = useContext(AuthContext)
  const previousAnswer = formContext.previousAnswer

  const [ vaccine, setVaccine ] = useState(formContext.secondDoseVaccine)
  const [ secondDate, setSecondDate ] = useState(formContext.secondDoseDate ? formContext.secondDoseDate : '')
  const [ error, setError ] = useState(false)
  const [ answer ] = useState(previousAnswer ? previousAnswer : [])
  const [ spinner, setSpinner ] = useState(false)

  const handleVaccineChange = (e) => {
    e.preventDefault()
    formContext.setSecondDoseVaccine(e.target.value)
    setVaccine(e.target.value)
  }

  const handleBack = (e) => {
    e.preventDefault()
    if (secondDate) {
      formContext.setSecondDoseDate(secondDate)
    }
    history.push('/form/section7')
  }

  const handleNext = async (e) => {
    e.preventDefault()
    if (secondDate) {
      formContext.setSecondDoseDate(secondDate)
    }

    if (vaccine) {
      setSpinner(true)
      const finalData = await getFinalData()
      formContext.submitUpdate(finalData)
      setSpinner(false)
      history.push('/form/end')
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if (formContext.secondDoseVaccine) {
      setVaccine(formContext.secondDoseVaccine)
    } else {
      setVaccine(previousAnswer[12])
    }

    if (secondDate) {
      setSecondDate(secondDate)
    } else {
      const previousDate = previousAnswer ? previousAnswer[11] : ''
      setSecondDate(previousDate ? new Date(previousDate) : '')
    }
  }, [])

  const getFinalData = () => {
    const second = secondDate ? secondDate : previousAnswer ? previousAnswer[11]:''
    const first = formContext.firstDoseDate ? formContext.firstDoseDate : previousAnswer ? previousAnswer[8] : ''
    const final = {
      date: moment(new Date()).format('MM/DD/YYYY HH:mm:ss'),
      currentuser: authContext.currentUser,
      vaccinationWillingness: formContext.vaccinationWillingness ? formContext.vaccinationWillingness: previousAnswer ? previousAnswer[1]: '',
      thoughts: formContext.thoughts ? formContext.thoughts : previousAnswer ? previousAnswer[2] : '',
      registrationCompletion: formContext.registrationCompletion ? formContext.registrationCompletion : previousAnswer ? previousAnswer[3] : '',
      registrationPlatform: formContext.registrationPlatform ? formContext.registrationPlatform : previousAnswer ? previousAnswer[4] : '',
      mySJID: formContext.mySJID ? formContext.mySJID : previousAnswer ? previousAnswer[5] : '',
      bookingConfirmation: formContext.bookingConfirmation ? formContext.bookingConfirmation : previousAnswer ? previousAnswer[6] : '',
      firstDoseConfirmation: formContext.firstDoseConfirmation ? formContext.firstDoseConfirmation : previousAnswer ? previousAnswer[7] : '',
      firstDoseDate: moment(first).format('MM/DD/YYYY'),
      firstDoseVaccine: formContext.firstDoseVaccine ? formContext.firstDoseVaccine : previousAnswer ? previousAnswer[9] : '',
      secondDoseConfirmation: formContext.secondDoseConfirmation ? formContext.secondDoseConfirmation : previousAnswer ? previousAnswer[10]: '',
      secondDoseDate: moment(second).format('MM/DD/YYYY'),
      secondDoseVaccine:formContext.secondDoseVaccine ? formContext.secondDoseVaccine : previousAnswer ? previousAnswer[12]: '',
      vaccinationStatusUpdate: formContext.vaccinationStatusUpdate
    }

    return final
  }

  if (spinner) {
    return(
      <Fragment>
        <Spinner />
      </Fragment>
    )
  } else {
    return(
      <Fragment>
        <div className='container'>
          <div className='text-center align-items-center justify-content-center mt-2'>
            <img src={TakeTheShot} alt='Take The Shot'/>
          </div>
          <div className='required required-label'>* Required</div>
          <div className='form-margin align-items-center justify-content-center mt-3'>
            <div className='card'>
              <div className='card-header'>
                <h3 className='card-title card-sub-title'>Your 2nd dose vaccine appointment</h3>
              </div>
              <div className='card-body'>
                <label className='question'>Date of my second vaccination dose<span className='required'>*</span></label>
                <div><label className='text-small'>Previous Answer: {answer[11] || 'N/A'}</label></div>
                <div> Date: &nbsp;&nbsp;
                <DatePicker 
                  selected={secondDate}
                  onChange={date => setSecondDate(date)} 
                  dateFormat='dd/MM/yyyy'
                />
                </div>
              </div>
            </div>
            <div className='mt-2'>
              <div className='card-body-question'>
                <div className='form-group'>
                  { error ? 
                    <div className="alert alert-danger" role="alert">
                      This is a required question. Please choose one option.
                    </div> : ''
                  }
                  <label className='question'>Select the vaccine that you received:<span className='required'>*</span></label>
                  <div><label className='text-small'>Previous Answer: {answer[12] || 'N/A'}</label></div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={vaccine === 'Pfizer'}
                        className='mr-2'
                        type='radio'
                        value='Pfizer'
                        onChange={handleVaccineChange}
                        required
                      />
                      Pfizer
                    </label>
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={vaccine === 'Astra Zeneca'}
                        className='mr-2'
                        type='radio'
                        value='Astra Zeneca'
                        onChange={handleVaccineChange}
                        required
                      />
                      Astra Zeneca
                    </label>               
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={vaccine === 'Sinovac'}
                        className='mr-2'
                        type='radio'
                        value='Sinovac'
                        onChange={handleVaccineChange}
                        required
                      />
                      Sinovac
                    </label>               
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={vaccine === 'Sputnik V'}
                        className='mr-2'
                        type='radio'
                        value='Sputnik V'
                        onChange={handleVaccineChange}
                        required
                      />
                      Sputnik V
                    </label>               
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={vaccine === 'CanSino'}
                        className='mr-2'
                        type='radio'
                        value='CanSino'
                        onChange={handleVaccineChange}
                        required
                      />
                      CanSino
                    </label>               
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={vaccine === 'Sinopharm'}
                        className='mr-2'
                        type='radio'
                        value='Sinopharm'
                        onChange={handleVaccineChange}
                        required
                      />
                      Sinopharm
                    </label>               
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={vaccine === 'Other'}
                        className='mr-2'
                        type='radio'
                        value='Other'
                        onChange={handleVaccineChange}
                        required
                      />
                      Other
                    </label>               
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={vaccine === 'Unknown'}
                        className='mr-2'
                        type='radio'
                        value='Unknown'
                        onChange={handleVaccineChange}
                        required
                      />
                      Unknown
                    </label>               
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex mt-3 mb-5'>
              <button type='submit' onClick={handleBack} className='btn btn-dark'>Back</button>
              <span className='mx-2'></span>
              <button type='submit' onClick={handleNext} className='btn btn-dark'>Next</button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Section8
import React, { Fragment, useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import TakeTheShot from '../../images/take-the-shot.png'
import FormContext from '../context/form/formContext'

const Section2 = () => {
  const history = useHistory()
  const formContext = useContext(FormContext)
  const previousAnswer = formContext.previousAnswer

  const [ vaccineWillingness, setVaccineWillingness ] = useState(formContext.vaccinationWillingness)
  const [ error, setError ] = useState(false)
  const [ answer ] = useState(previousAnswer ? previousAnswer : [])

  const handleChange = (e) => {
    formContext.setVaccinationWillingness(e.target.value)
    setVaccineWillingness(e.target.value)
  }
  
  const handleBack = (e) => {
    e.preventDefault()
    history.push('/form')
  }

  const handleNext = (e) => {
    e.preventDefault()
    if (vaccineWillingness) {
      if(vaccineWillingness == 'No') {
        history.push('/form/section3')
      } else {
        history.push('/form/section4')
      }
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if (formContext.vaccinationWillingness) {
      setVaccineWillingness(formContext.vaccinationWillingness)
    } else {
      setVaccineWillingness(previousAnswer[1])
    }
  }, [])

  return (
    <Fragment>
      <div className='container'>
        <div className='text-center align-items-center justify-content-center mt-2'>
          <img src={TakeTheShot} alt='Take The Shot'/>
        </div>
        <div className='form-margin align-items-center justify-content-center mt-3'>
          <div className='card'>
            <div className='card-body'>
              <h3 className='card-title'>Update your vaccination status</h3>
              <p className='card-text'>
                Please provide your latest status by editing your vaccination profile below.
              </p>
            </div>
          </div>
        </div>
        <div className='required required-label'>* Required</div>
        <div className='form-margin align-items-center justify-content-center mt-3'>
          {/* <div className='card'> */}
          <form>
            <div className='card-body card-body-question'>
                <div className='form-group'>
                  { error ? 
                    <div className="alert alert-danger" role="alert">
                      This is a required question. Please choose one option.
                    </div> : ''
                  }
                  <label className='question'>
                    Are you willing to get a vaccine for COVID-19?
                    <span className='required'>*</span>
                    </label>
                  <div><label className='text-small'><b>Previous Answer:</b> {answer[1] || 'N/A'}</label></div>
                  <div className='radio'>
                    <label>
                      <input
                        checked={vaccineWillingness === 'Yes'}
                        className='mr-2'
                        type='radio'
                        value='Yes'
                        onChange={handleChange}
                      />
                      Yes
                    </label>
                  </div>
                  <div className='radio'>
                  <label>
                      <input
                        checked={vaccineWillingness === 'No'}
                        className='mr-2'
                        type='radio'
                        value='No'
                        onChange={handleChange}
                      />
                      No
                    </label>
                  </div>
                </div>
            </div>
            <div className='d-flex'>
                <button onClick={handleBack} className='btn btn-dark' >Back</button>
                <span className='mx-2'></span>
                <button onClick={handleNext} className='btn btn-dark'>Next</button>
              </div>
          </form>
          {/* </div> */}
        </div>             
      </div>
    </Fragment>
  )
}

export default Section2